import React from 'react';
import { BrowserRouter as Router, Switch, Routes, Route, Link } from 'react-router-dom';

export default function sidebarNav() {
    function toggleMenu(){
      let bodySelector = document.querySelector("#root");
      let toggleNavigation = document.querySelector(".toggle-navigation");
      if(toggleNavigation.classList.contains('opened') == false){
        toggleNavigation.classList.add('opened');
        bodySelector.classList.add('navigation-opened');
        toggleNavigation.setAttribute('aria-expanded', 'true');
      } else{
        toggleNavigation.classList.remove('opened');
        bodySelector.classList.remove('navigation-opened');
        toggleNavigation.setAttribute('aria-expanded', 'false');
      }
    }
    return(
      <div className='col-3 col-md-3 col-lg-2 sidebar-area'>
        <button type='button' className='toggle-navigation bg-blue' onClick={toggleMenu} aria-label="Main Menu"><svg width="30" height="30" viewBox="0 0 100 100"><path className="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" /><path className="line line2" d="M 20,50 H 80" /><path className="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" /></svg></button>
        <div className='logo-bar w-100 text-center mb-2'>
          <img width="100" height="auto" src="/milgenx-full.png" alt="platform-logo" className="platform-logo" />
        </div>
        <nav className="navbar">
          <ul className="link-list">
            <li><Link to={'/daily-records'} className="nav-link">Records/Entries</Link></li>
            <li><Link to={'/clients'} className="nav-link">Clients</Link></li>
            <li><Link to={'/tracking'} className="nav-link">Tracking</Link></li>
            <li><Link to={'/projects'} className="nav-link">Projects</Link></li>
            <li><Link to={'/analysis'} className="nav-link">Analysis</Link></li>
          </ul>
        </nav>
      </div>
    );
}