import React, { Component }  from 'react';
import { useNavigate, Link } from "react-router-dom";
import {isLoggedIn} from '../helpers.js';
import Cookies from 'js-cookie';

let userData = isLoggedIn(Cookies);
console.log({userData})
const Notallowed = () => {
    const navigate = useNavigate();

    if(userData.loggedIn === 'true' && Number(userData.accessAllowed) === 0){
        return (
            <div className='authentication-required justify-content-center my-5 align-items-center not-allowed text-center'>
                <h2><b>Pending Approval</b></h2>
                <div className='w-100 text-center text-small'>
                    Please contact the admin for more information.
                </div>
            </div>
        )
    }else{
        setTimeout(function(){
            navigate("/my-account");
        }, 5000)
        return (
            <div className='authentication-required justify-content-center my-5 align-items-center not-allowed text-center'>
                <h2><b>Not Authorized</b></h2>
                <div className='w-100 text-center text-small'>
                    Please login to view this page. <br/>if not redirected, click to <Link to={'/my-account'} className="p-link-text">Login/Signup</Link>
                </div>
            </div>
        )
    }
}

export default Notallowed;