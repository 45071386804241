import React, {useRef, useState, useEffect, PureComponent} from 'react';
import axios, {isCancel, AxiosError} from 'axios';
import { Brush, AreaChart, Area, LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { kindOfContracts, sumOfColumn, calcTime, sumValues, compareStrings, isLoggedIn, getPrivacyStandard, splitRows, splitColumn, removeDuplicates, parseDate, getDaysArray } from '../helpers.js';
import { Calendar } from 'primereact/calendar/calendar.esm.js';
import { SelectButton } from 'primereact/selectbutton/selectbutton.esm.js';
import { Dropdown } from 'primereact/dropdown/dropdown.esm.js';
import Cookies from 'js-cookie';
let privacyControl = getPrivacyStandard(Cookies);

let domainOrigin = "";
let origin = window.origin;
if(origin.includes("reporting.milgenx.com")){
    domainOrigin = "https://reportingapi.milgenx.com";
}else{
    domainOrigin = "http://localhost:8080";   
}

const Billinganalysis = (props) => {
    let typeOfContracts = kindOfContracts();
    let allItems = props.items;
    console.log({allItems})
    let staffs = []
    if(props.items){
        let allStaffs = removeDuplicates(splitColumn(allItems, "freelancer_name"));
        allStaffs.forEach(function(staff, i){
            staffs.push({staff: staff})
            if (i === allStaffs.length - 1){ 
                staffs.push({staff: "All Staff"})
            }
        })
    }


    const options = ['Current User', 'All User'];
    const [staff, setStaff] = useState(null);
    
    const filterOptions = ['By Client', 'By Dates'];
    const [filterview, setFilterView] = useState(filterOptions[0]);

    console.log({staff})
    // Filter records by user name
    let userDetails = props.userDetails;
    let currentUserName = userDetails.userName;
    let currentUserFName = currentUserName.split(" ").first;
    let userRecords = "";
    if(staff){ currentUserName = staff.staff }
    if(currentUserName){
        console.log({currentUserName})
        userRecords = splitRows(allItems, "freelancer_name", currentUserName);
    }else if(staff == "All Staff"){
        userRecords = allItems;
    }

    let allClients = [];
    let recordByClient = [];
    let recordByDates = [];

    const [dateRange, setdateRange] = useState(null);
    let fromDate = '';
    let endDate = '';
    if(dateRange){
        console.log("1")
        let unFilteredRecords = userRecords;
        if(dateRange[0]){
            fromDate = parseDate(dateRange[0]);
        }
        if(dateRange[1]){
            endDate = parseDate(dateRange[1]);
        }

        var selectedDays = [];
        let filteredRecords = []
        if(fromDate && endDate){
            console.log("2")
            selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
            selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");
            selectedDays.forEach(function(selectedDay){
                let recordDay = parseDate(selectedDay);
                filteredRecords.push(splitRows(unFilteredRecords, 'date', recordDay));
                if(splitRows(userRecords, 'date', recordDay).length > 0){
                    recordByDates.push({
                        records: splitRows(userRecords, 'date', recordDay),
                        "date": recordDay,
                        "Billed Hours": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', recordDay), 'type_of_contract', 'billable'), "worked_hours"))), 
                        "Billable By Invoice": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', recordDay), 'type_of_contract', 'billable-by-invoice'), "worked_hours"))), 
                        "Non Billed Hours": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', recordDay), 'type_of_contract', 'non-billable'), "worked_hours"))), 
                        "Billed Fixed Hours": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', recordDay), 'type_of_contract', 'fixed-cost'), "worked_hours"))), 
                        "Total Worked Hours": Number(calcTime(splitColumn(splitRows(unFilteredRecords, 'date', recordDay), "worked_hours")))
                    });
                }
            })
        }else{
            console.log("3")
            selectedDays = parseDate(dateRange[0]);
            filteredRecords.push(splitRows(unFilteredRecords, 'date', selectedDays));
            if(splitRows(userRecords, 'date', selectedDays).length > 0){
                recordByDates.push({
                    records: splitRows(userRecords, 'date', selectedDays),
                    "date": selectedDays,
                    "Billed Hours": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', selectedDays), 'type_of_contract', 'billable'), "worked_hours"))),
                    "Billable By Invoice": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', selectedDays), 'type_of_contract', 'billable-by-invoice'), "worked_hours"))),  
                    "Non Billed Hours": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', selectedDays), 'type_of_contract', 'non-billable'), "worked_hours"))), 
                    "Billed Fixed Hours": Number(calcTime(splitColumn(splitRows(splitRows(unFilteredRecords, 'date', selectedDays), 'type_of_contract', 'fixed-cost'), "worked_hours"))), 
                    "Total Worked Hours": Number(calcTime(splitColumn(splitRows(unFilteredRecords, 'date', selectedDays), "worked_hours")))
                });
            }
        }
        
        

        if(filteredRecords.length > 0){
            filteredRecords = filteredRecords.flat();
            allClients = splitColumn(filteredRecords, "client_name");
            userRecords = filteredRecords;
        }
    }else{
        console.log("4")
        allClients = (userRecords.length > 0 ) ? splitColumn(userRecords, "client_name") : [];
        let filteredRecords = [];


        var currentDate = new Date();
        let fromDate = parseDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
        let endDate = parseDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))

        if(fromDate && endDate){
            selectedDays = getDaysArray(new Date(fromDate),new Date(endDate));
            selectedDays.map((v)=>v.toISOString().slice(0,10)).join("");
            selectedDays.forEach(function(selectedDay){
                let recordDay = parseDate(selectedDay);
                filteredRecords.push(splitRows(userRecords, 'date', recordDay));
                if(splitRows(userRecords, 'date', recordDay).length > 0){
                    recordByDates.push({
                        records: splitRows(userRecords, 'date', recordDay),
                        "date": recordDay,
                        "Billed Hours": Number(calcTime(splitColumn(splitRows(splitRows(userRecords, 'date', recordDay), 'type_of_contract', 'billable'), "worked_hours"))), 
                        "Billable By Invoice": Number(calcTime(splitColumn(splitRows(splitRows(userRecords, 'date', recordDay), 'type_of_contract', 'billable-by-invoice'), "worked_hours"))),  
                        "Non Billed Hours": Number(calcTime(splitColumn(splitRows(splitRows(userRecords, 'date', recordDay), 'type_of_contract', 'non-billable'), "worked_hours"))), 
                        "Billed Fixed Hours": Number(calcTime(splitColumn(splitRows(splitRows(userRecords, 'date', recordDay), 'type_of_contract', 'fixed-cost'), "worked_hours"))), 
                        "Total Worked Hours": Number(calcTime(splitColumn(splitRows(userRecords, 'date', recordDay), "worked_hours")))
                    });
                }
            })
        }

        if(filteredRecords.length > 0){
            filteredRecords = filteredRecords.flat();
            allClients = (userRecords.length > 0 ) ? splitColumn(filteredRecords, "client_name") : [];
            userRecords = filteredRecords;
        }
    }

    allClients = removeDuplicates(allClients);

    allClients.forEach(function(client, index){
        let clientRecords = splitRows(userRecords, "client_name", client);
        let clientRecordsByContract, records = [];
        let totalBilledTimeSpents = 0;
        let totalNonBilledTimeSpents = 0;
        let totalFixedTimeSpents = 0;
        let totalTimeSpents = 0;
        let totalInvoiceBilledTimeSpents = 0;

        typeOfContracts.forEach(function(type_of_contract){
            let typeOfContract = type_of_contract.code
            // For total time spents
            clientRecordsByContract = splitRows(clientRecords, "type_of_contract", typeOfContract);
          
            let timeSpents = splitColumn(clientRecordsByContract, "worked_hours");

            // Total time spents calculation
            timeSpents.forEach(function(timeSpent){
                let TotalMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                let workedHours = Number(TotalMinute / 60);
                totalTimeSpents = (workedHours) ? totalTimeSpents + workedHours : totalTimeSpents + 0

                // Total Billed Hours Calculation
                if(typeOfContract == 'billable'){
                    let TotalBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let BilledWorkedHours = Number(TotalBilledMinute / 60);
                    totalBilledTimeSpents = (BilledWorkedHours) ? Number(totalBilledTimeSpents + BilledWorkedHours) : Number(totalBilledTimeSpents + 0);
                }

                if(typeOfContract == 'billable-by-invoice'){
                    let TotalInvoicedMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let InvoicedWorkedHours = Number(TotalInvoicedMinute / 60);
                    totalInvoiceBilledTimeSpents = (InvoicedWorkedHours) ? totalInvoiceBilledTimeSpents + InvoicedWorkedHours : totalInvoiceBilledTimeSpents + 0;
                }

                // Total Non Billed Hours Calculation
                if(typeOfContract == 'non-billable'){
                    let TotalNonBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let NonBilledWorkedHours = Number(TotalNonBilledMinute / 60);
                    totalNonBilledTimeSpents = (NonBilledWorkedHours) ? totalNonBilledTimeSpents + NonBilledWorkedHours : totalNonBilledTimeSpents + 0;
                }

                // Total Fixed Hours Calculation
                if(typeOfContract == 'fixed-cost'){
                    let TotalFixedBilledMinute = Number(timeSpent.split(":")[0] * 60) + Number(timeSpent.split(":")[1]);
                    let FixedWorkedHours = Number(TotalFixedBilledMinute / 60);
                    totalFixedTimeSpents = totalFixedTimeSpents + FixedWorkedHours;
                }
            })
            records.push({contractType:typeOfContract, contractRecords: clientRecordsByContract});
        })
        recordByClient.push({clientName: client, clientRecords: clientRecords, clientRecordsByContract: records, "Billed Hours": totalBilledTimeSpents.toFixed(2), "Billable By Invoice": totalInvoiceBilledTimeSpents.toFixed(2), "Non Billed Hours": totalNonBilledTimeSpents.toFixed(2), "Billed Fixed Hours": totalFixedTimeSpents.toFixed(2), "Total Worked Hours": totalTimeSpents.toFixed(2) });
    })

    console.log({recordByClient, recordByDates})
    // console.log({TableName, productHandle});   
    let [listCount, setListCount] = useState(1);
    const [ignored, forceUpdate] = useState(listCount);

    const [date, setDate] = useState(null);

    let worked_hours = sumOfColumn(splitColumn(recordByClient, "Total Worked Hours")).toFixed(2);
    let billed_hours = sumOfColumn(splitColumn(recordByClient, "Billed Hours")).toFixed(2);
    let invoice_billed_hours = sumOfColumn(splitColumn(recordByClient, "Billable By Invoice")).toFixed(2);
    let nonbilled_hours = sumOfColumn(splitColumn(recordByClient, "Non Billed Hours")).toFixed(2);
    let fixedcost_hours = sumOfColumn(splitColumn(recordByClient, "Billed Fixed Hours")).toFixed(2);

    return (
    <div className='container-fluid'>
        <div className='row py-2 flex-wrap mb-2 align-items-center'>
            <div className='col-6 col-sm-6 col-md-3'>
                <Calendar value={dateRange} onChange={(e) => setdateRange(e.value)} selectionMode="range" readOnlyInput showIcon showButtonBar className='text-small' />
            </div>
            {privacyControl == false &&
            <div className='col-6 col-sm-6 col-md-9 d-flex justify-content-end'>
                <Dropdown value={staff} defaultValue={currentUserName} onChange={(e) => setStaff(e.value)} options={staffs} optionLabel="staff" 
                placeholder={currentUserName} className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
            </div>}
        </div>
        <div className='row py-2'>
            <div className='col-12 col-sm-6 col-md-3 mb-2'>
                <div className='status-block w-full bg-blue'>
                    <small>Worked Hrs: </small>
                    <strong>{worked_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-6 col-md-2 mb-2'>
                <div className='status-block w-full label-billable'>
                    <small>Billed: </small>
                    <strong>{billed_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-4 col-md-2 mb-2'>
                <div className='status-block w-full label-billable-by-invoice'>
                    <small>Invoiced: </small>
                    <strong>{invoice_billed_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-4 col-md-2 mb-2'>
                <div className='status-block w-full label-fixed-cost'>
                    <small>Fixed Billed: </small>
                    <strong>{fixedcost_hours}</strong>
                </div>
            </div>
            <div className='col-6 col-sm-4 col-md-3 mb-2'>
                <div className='status-block w-full label-non-billable'>
                    <small>Non Billed: </small>
                    <strong>{nonbilled_hours}</strong>
                </div>
            </div>
        </div>
        <ResponsiveContainer width="100%" height="100%" className="fit-inbody">
            <BarChart width={500} height={300} data={(filterview == "By Dates") ? recordByDates : recordByClient} margin={{ top: 20, right: 10, left: 0, bottom: 5, }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={(filterview == "By Dates") ? "date" : "clientName"} />
            <YAxis dataKey="Total Worked Hours"/>
            <Tooltip />
            <Legend />
            <Bar dataKey="Billed Hours" stackId="a" fill="#13544e" />
            <Bar dataKey="Billable By Invoice" stackId="a" fill="#5d8fe1" />
            <Bar dataKey="Billed Fixed Hours" stackId="a" fill="orange" />
            <Bar dataKey="Non Billed Hours" stackId="a" fill="maroon" />
            {/* <Bar dataKey="Total Worked Hours" stackId="a" fill="#6366F1" /> */}
            </BarChart>
        </ResponsiveContainer>
        <div className='pt-3 pb-5 col-12 col-sm-12 col-md-12 d-flex justify-content-center'>
            <SelectButton value={filterview} onChange={(e) => setFilterView(e.value)} options={filterOptions} />
        </div>
    </div>
    )
} 

export default Billinganalysis;